export const listItems = [
  
    {
        id: 1,
        desc: "Home",
        path: 'home'
    },

    {
        id: 2,
        desc: "Services",
        path: 'services'
    },

    {
        id: 3,
        desc: "Socials",
        path: 'contact'
    },

   


]