


import MainPage from './Pages/MainPage';

function App() {
  return (
    
       <MainPage/>
      
    
  );
}

export default App;
